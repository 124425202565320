.TnCproducts{
    /* display: flex; */
    justify-content: center;
    /* align-items: center; */
    margin: 20px 50px 20px 50px;
    margin-top: 20px;
    color: #000000;
}
.TnC {
    margin: 20px 20px 20px 20px;
    width: 70vh;
    line-height: 1.2;
    font-size: 22px;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    color: #5c5c5c;

}

.TnCproducts mark{
    margin: 20px 50px 20px 50px;
}
.TnCproducts h1{
    color: #33334d;
    margin: 20px 50px 20px 50px;
    /* align-items: flex-end; */
    /* font-size: 32px; */
    /* margin-left: 50px; */
    align-items: center;
}


.TnCproducts p{
    font-size: 16px;
    margin: 20px 50px 20px 50px;
    /* margin-left: 550px; */
    align-items: center;
    text-align: justify;
}

.TnCproducts strong{
    content: "/A";
    color: #33334d;
    display: block;
    margin: 20px 50px 20px 50px;
    font-size: 22px;
} 