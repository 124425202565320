.breadcrums{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    color: #5e5e5e;
    font-size: 14px;
    font-weight: 500;
    margin: 20px 10%;
}
.breadcrums img{
    height: 12px;
}

@media(max-width:1024px){
    .breadcrums{
        margin: 20px 8%;
    }
}
@media(max-width:800px){
    .breadcrums{
        margin: 20px 5%;
    }
    .breadcrums img{
        height: 10px;
    }
}
